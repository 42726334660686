import styled from '@emotion/styled'

export const Wrapper = styled.div(
  {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '22.5px',
    position: 'relative',
    width: 'fit-content',

    '.copied': {
      bottom: 'calc(100% + 5px)',
      boxShadow: '0px 6px 12px 2px rgba(2, 2, 2, 0.05)',
      fontSize: '0.8rem',
      fontWeight: 400,
      left: 'calc(50% - 65px)',
      padding: '5px 10px',
      position: 'absolute',
      zIndex: 1,
    },
  },
  ({ theme }) => ({
    '.copied': {
      background: theme.colors.backgrounds.primary,
      borderRadius: theme.borderRadius.default,
      color: theme.colors.primary,
    },
  }),
)

export const CopyableImage = styled.div({
  marginLeft: '5px',
  marginBottom: '5px',
})
