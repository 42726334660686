import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'

import { useEnvironment } from '@context/environment'

import Code from '../code'
import { CopyableImage, Wrapper } from './style'
import { CodeCopied } from '@lib/analytics'

const COPIED_TEXT_TIMEOUT = 1500

type CopyableCodeProps = {
  id?: string
  value: string
}

const CopyableCode = ({ value, id }: CopyableCodeProps): JSX.Element => {
  // Derive context.
  const { environment } = useEnvironment()

  // Derive state.
  const [isCopied, setIsCopied] = useState<boolean>(false)

  // Derive refs.
  const timeoutRef = useRef<NodeJS.Timeout>()

  // Prevent memory leak if user navigates away.
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const onCopy = async () => {
    // Copy the value to the clipboard.
    await navigator.clipboard.writeText(value)

    // Track the event.
    if (environment) {
      analytics.track(CodeCopied, {
        environmentId: environment.id,
        environmentName: environment.name,
      })
    }

    // Show the "Copied!" text.
    setIsCopied(true)

    // Clear the "Copied!" text after 1.5 seconds.
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setIsCopied(false)
    }, COPIED_TEXT_TIMEOUT)
  }

  return (
    <Wrapper
      aria-label={'Copy'}
      onClick={onCopy}
      role="button"
      tabIndex={0}
    >
      {isCopied && <span className="copied">Copied!</span>}
      <Code id={id}>{value}</Code>
      <CopyableImage>
        <Image alt="Copy" height={18} src="/icons/icon-copy.svg" width={18} />
      </CopyableImage>
    </Wrapper>
  )
}

export default CopyableCode
